<template>
  <VFlex
    v-if="typeof report === 'object'"
    :class="[
      'shift',
      { 'night-shift': isNightShift },
      { 'new-shift': isNew },
      { protected: isProtected },
      { 'has-notes': hasNotes },
      { 'is-processing': isProcessing },
      { 'cannot-drag': cannotDrag },
      { ghost: report.isGhost },
      isImmutable ? 'immutable' : 'mutable',
      elevation,
      { 'green accent-2': showReportModal },
      { 'loc-hover green accent-2': isMyLocationHovering },
      { 'on-hold': isLocationOnHold },
      { 'needs-setup': needsSetup && !isProcessing },
    ]"
    text-md-left
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
    @click.stop="reportClick"
  >
    <BaseSpinner
      v-if="isProcessing"
      size="small"
      line-fg-color="#BBB"
      margin="5"
    />
    <div v-else>
      <div class="cal-cell-data job-number"
        >{{ report.JOB_NUMBER }} - {{ location.CLIENT_NAME }}
        <VIcon v-if="isLocked" small color="grey" class="right">lock</VIcon>
        <VIcon
          v-if="isLocationOnHold"
          small
          color="orange"
          class="on-hold-icon right"
          >pause_circle_filled</VIcon
        >

        <VIcon v-else-if="isCompleted" small color="grey" class="right"
          >check</VIcon
        >
      </div>
      <div class="cal-cell-data small-data" :title="location.IDENTIFIER">{{
        location.IDENTIFIER
      }}</div>
      <div class="cal-cell-data small-data">{{
        fiberJobAttrs.join(' | ')
      }}</div>
      <VIcon v-if="isNightShift" small color="#136085" class="night-shift-icon"
        >brightness_3</VIcon
      >
      <VIcon v-if="hasNotes" small color="#c837d5">note</VIcon>
      <VIcon
        v-if="isVzInspectionDate"
        small
        color="#2c70ea"
        class="d-inline text-xs-right mx-1"
        >person_search</VIcon
      >
      <VIcon
        v-if="isInstall5gFoundationDate"
        small
        color="#de7113"
        class="d-inline text-xs-right mx-1"
        >5g</VIcon
      >
      <ReportModal
        v-if="showReportModal"
        :report="report"
        :location="location"
        :show="showReportModal"
        @hide="showReportModal = false"
      />
    </div>
  </VFlex>
</template>

<script>
import { ReportStatuses, JobTypes } from '@constants/knack'
import _get from 'lodash/get'
import { mapGetters, mapActions } from 'vuex'
import dayjs from '@utils/dayjsCustomParse'

import ReportModal from '@modals/CalCellReport/ReportModal'
// const ModalBasic = () =>
//   import(/* webpackChunkName: "ModalBasic" */ '@modals/ModalBasic.vue')
export default {
  name: 'CalCellReport',
  components: { ReportModal },
  props: {
    report: {
      type: [Object, String],
      default: '',
    },
    location: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isNew: false,
      isHovering: false,
      showReportModal: false,
      isPopupWorking: false,
    }
  },
  computed: {
    ...mapGetters(['getHoveringLocationId']),

    isNightShift() {
      return this.workHours ? this.workHours.includes('Nights') : false
    }, // isNightShift
    isProtected() {
      return _get(this, 'location.PROTECTED', 0) === 'Yes'
    }, // isProtected
    isCompleted() {
      return _get(this, 'report.REPORT_STATUS') === ReportStatuses.COMPLETED
    }, // isCompleted
    isLocked() {
      return _get(this, 'report.REPORT_STATUS') === ReportStatuses.LOCKED
    }, // isLocked
    isImmutable() {
      return this.isLocked || this.isCompleted || this.isProcessing
    }, // isImmutable
    isProcessing() {
      return _get(this, 'report.isProcessing') || !_get(this, 'location.ID')
    }, // isProcessing
    hasNotes() {
      return _get(this, 'location.PROJECT_MANAGER_NOTES', '').length > 0
    },
    workHours() {
      let hours = _get(this, 'location.PERMIT__WORK_HOURS', false)
      return hours.length ? hours : ['M-F (Default)']
    }, // workHours
    cannotDrag() {
      return this.isImmutable || this.report.isGhost || this.isLocationOnHold
    }, // cannotDrag
    isLocationOnHold() {
      return _get(this, 'location.ON_HOLD')
    }, // isLocationOnHold

    elevation() {
      return this.isImmutable
        ? this.isHovering
          ? 'elevation-2'
          : 'elevation-0'
        : this.isHovering
        ? 'elevation-3'
        : 'elevation-1'
    }, // elevation
    isMyLocationHovering() {
      return this.getHoveringLocationId === _get(this, 'location.ID')
    },
    needsSetup() {
      let mostRecentSetupObj = dayjs(
        _get(this.location, 'MOST_RECENT_SETUP_DATE_FORMULA.date', ''),
        'MM/DD/YYYY'
      )
      // If no setup is found, it needs a setup
      if (!mostRecentSetupObj.isValid()) {
        return true
      }

      let reportDateObj = dayjs(
        _get(this.report, 'REPORT_DATE.date', ''),
        'MM/DD/YYYY'
      )
      return reportDateObj.isAfter(mostRecentSetupObj.add(10, 'day'))
    }, // needsSetup

    fiberJobAttrs() {
      if (!this.isFiberJob) return []
      let attrs = [this.location.LF]
      attrs.push(this.protectedStatus)
      attrs.push(_get(this, 'location.JOB_TYPE', []).join(', '))
      if (this.location.VZ_INSPECTION_REQUIRED) {
        attrs.push('VZ')
      }
      return attrs
    },
    protectedStatus() {
      let lookup = {
        Yes: 'P',
        No: 'NP',
        Both: 'P/NP',
        '': '–',
      }
      let protectedValue = _get(this, 'location.PROTECTED', '')
      return lookup[protectedValue] || false
    }, // protectedStatus
    isFiberJob() {
      return [
        JobTypes.FIBER,
        JobTypes.MICRO,
        JobTypes.MICRO_REPAIR,
        JobTypes.POWER_DIG,
        JobTypes.EMERGENCY,
      ].includes(this.jobType)
    },
    isMicroFiberJob() {
      return [JobTypes.MICRO, JobTypes.MICRO_REPAIR].includes(this.jobType)
    },
    jobType() {
      return _get(this, 'location.JOB_TYPE[0]', '')
    },
    isVzInspectionDate() {
      let locationVzInspectionDate = _get(
        this,
        'location.VZ_INSPECTION_DATE.date'
      )
      if (!locationVzInspectionDate) return false
      let reportDate = _get(this, 'report.REPORT_DATE.date')
      if (!reportDate) return false
      return dayjs(reportDate, 'MM/DD/YYYY').isSame(
        dayjs(locationVzInspectionDate, 'MM/DD/YYYY')
      )
    },
    isInstall5gFoundationDate() {
      let locationInstall5gFoundationDate = _get(
        this,
        'location.INSTALL_5G_FOUNDATION_DATE.date'
      )
      if (!locationInstall5gFoundationDate) return false
      let reportDate = _get(this, 'report.REPORT_DATE.date')
      if (!reportDate) return false
      return dayjs(reportDate, 'MM/DD/YYYY').isSame(
        dayjs(locationInstall5gFoundationDate, 'MM/DD/YYYY')
      )
    },
  },
  created() {
    setTimeout(() => {
      this.isNew = false
    }, 500)
  },
  methods: {
    ...mapActions(['fetchLocation']),
    ...mapGetters(['getLocationFromId']),
    reportClick() {
      if (this.report.isGhost || this.isProcessing) {
        return false
      }
      this.showReportModal = true
    },
  }, // methods
}
</script>

<style lang="scss">
.shift {
  position: relative;
  flex: 1 0 auto;
  align-items: center;
  padding: 5px 5px;
  margin-bottom: 2px;
  overflow: hidden;
  color: $gray-dark;
  cursor: move;
  background-color: white;
  // border: 1px solid $gray-light;
  border-radius: 6px;
  opacity: 1;
  transition: background-color 0.3s ease, box-shadow 0.2s ease,
    opacity 0.4s ease;

  .remove-shift-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  &:hover {
    overflow: visible;
  }
  .sortable-ghost {
    width: 100%;
  }
  .job-number {
    font-weight: bold;
  }
  .small-data {
    font-size: 0.8rem;
  }
  &.new-shift {
    background-color: orange !important;
  }
  &.immutable {
    // padding-bottom: 20px;
    background-color: darken(white, 20%);
    &:not(.loc-hover) {
      opacity: 0.5;
      @media print {
        opacity: 1;
      }
    }
  }
  &.ghost {
    opacity: 0.3;
  }
  &.cannot-drag {
    cursor: pointer;
    user-select: none;
  }
  &.needs-setup {
    background: lighten($orange, 40);
  }

  .cal-cell-data {
    line-height: 1.1;
    margin: 0.45em 0;
    @media print {
      font-size: 0.7rem;
      margin: 0.25em 0;
      &.small-data {
        font-size: 0.6rem;
      }
    }
  }

  @media print {
    border: 1px solid hsl(0, 0, 50%) !important;
    box-shadow: none !important;
    &::before {
      display: none !important;
    }
  }
} // .shift

.report-popup {
  .v-card__title {
    .title,
    .subtitle {
      font-family: 'Avenir', sans-serif !important;
      font-weight: 500;
      color: black;
    }
    .subtitle {
      margin-top: 0.3em;
    }
  }
}
</style>
