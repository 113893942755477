<template>
  <WithReportAssets
    :asset-type="assetType"
    :report-id="reportId"
    @loaded="$emit('assetsLoaded', $event)"
  >
    <VChip
      slot-scope="{ areAssignmentsLoading }"
      class="assignment-chip"
      color="grey lighten-2"
      outline
      text-color="black"
      disabled
    >
      <VAvatar
        :color="loadedAssets[assetType] ? 'blue darken-2' : 'grey lighten-1'"
      >
        <VIcon color="white">{{ icon }}</VIcon>
      </VAvatar>

      <BaseSpinner
        v-if="isDeleting[assetType] || areAssignmentsLoading"
        size="small"
      />
      <template v-else>
        {{ loadedAssets[assetType] || 0 }} {{ properAssetName }}
      </template>
    </VChip>
  </WithReportAssets>
</template>

<script>
import { VChip, VAvatar } from 'vuetify/lib'
import WithReportAssets from '@dataProviders/WithReportAssets'
import upperFirst from 'lodash/upperFirst'

export default {
  name: 'ReportModalAssetChip',
  components: { VChip, VAvatar, WithReportAssets },
  props: {
    assetType: {
      type: String,
      default: null,
    },
    reportId: {
      type: String,
      default: null,
    },
    loadedAssets: {
      type: Object,
      default: () => {},
    },
    isDeleting: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      default: 'widgets',
    },
  },
  data() {
    return {}
  },
  computed: {
    properAssetName() {
      return this.assetType
        .split('_')
        .map((token) => upperFirst(token))
        .join(' ')
    }, // properAssetName
  },
}
</script>
