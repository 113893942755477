<template>
  <div class="cloning-module">
    <transition name="fade-fast" mode="out-in">
      <div v-if="!isShowingControls">
        <BaseButton
          v-for="(type, assetType) of cloneableTypes"
          :key="type"
          color="blue"
          small
          :disabled="!loadedAssets[type]"
          @click="showControlsForType(assetType)"
          >Clone {{ assetType }}</BaseButton
        >
      </div>

      <VCard
        v-else
        :disabled="isCloning"
        class="elevation-3 pa-3 grey lighten-3"
      >
        <ModalCloseButton
          :disabled="isCloning"
          @click="isShowingControls = false"
        />
        <h2>{{ isCloning ? 'Cloning' : 'Clone' }} {{ humanReadableType }}</h2>
        <BasePicklist
          title="Pick Destination Report"
          :items="getReports.filter((r) => r.ID !== report.ID)"
          :value="destinationReportId"
          item-text="DAILY_REPORT_NAME"
          @change="destinationReportId = $event"
        />
        <div v-if="isLaborersSelected" class="my-1">
          <VCheckbox
            v-model="cloneStartTimes"
            :disabled="isCloning"
            dense
            hide-details
            label="Clone Start Times"
            color="primary"
            class="d-inline-block mr-2"
          />
          <VCheckbox
            v-model="cloneStartLocations"
            :disabled="isCloning"
            dense
            hide-details
            label="Clone Start Locations"
            color="primary"
            class="d-inline-block"
          />
        </div>
        <BaseButton
          :color="confirmClone ? 'orange' : 'teal'"
          :disabled="isCloning || !destinationReportId.length"
          :loading="isCloning"
          @click="maybeCloneAssets"
          >{{
            confirmClone ? 'Are you sure?' : `Clone ${humanReadableType}`
          }}</BaseButton
        >
        <BaseButton
          :disabled="isCloning"
          outline
          @click="isShowingControls = false"
          >Cancel</BaseButton
        >
      </VCard>
    </transition>
  </div>
</template>

<script>
import {
  AssetTypes,
  AssignmentTables,
  HumanFields,
  HumanReadableAssetTypes,
} from '@constants/knack'
import { mapActions, mapGetters } from 'vuex'
import fullstory from '@utils/fullstory'
import pAll from 'p-all'
import _get from 'lodash/get'

import { VCheckbox } from 'vuetify/lib'
import ModalCloseButton from '@modals/ModalCloseButton'

export default {
  name: 'ReportModalCloningModule',
  components: { ModalCloseButton, VCheckbox },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    loadedAssets: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShowingControls: false,
      isCloning: false,
      destinationReportId: '',
      humanReadableType: 'Laborers',
      confirmClone: false,
      cloneStartTimes: false,
      cloneStartLocations: false,
      cloneableTypes: {
        [HumanReadableAssetTypes.LABORER]: AssetTypes.LABORER,
        [HumanReadableAssetTypes.EQUIPMENT]: AssetTypes.EQUIPMENT,
        [HumanReadableAssetTypes.MATERIALS]: AssetTypes.MATERIALS,
      },
      destinationAssgnts: [],
      cloneableAssets: [],
    }
  },
  computed: {
    ...mapGetters(['getReports']),
    knackAssetType() {
      let lookupTable = {
        Laborers: AssetTypes.LABORER,
        Equipment: AssetTypes.EQUIPMENT,
        Materials: AssetTypes.MATERIALS,
      }
      return lookupTable[this.humanReadableType] || false
    },
    assignmentType() {
      return AssignmentTables[this.knackAssetType]
    },
    assetTypeKey() {
      let lookupTable = {
        Laborers: 'LABORER',
        Equipment: 'EQUIPMENT',
        Materials: 'MATERIALPART',
      }
      return lookupTable[this.humanReadableType]
    },
    assetIdKey() {
      return `${this.assetTypeKey}[0].id`
    },
    reportConnectionKey() {
      return HumanFields[this.assignmentType].DAILY_REPORT
    },
    assetConnectionKey() {
      return HumanFields[this.assignmentType][this.assetTypeKey]
    },
    isLaborersSelected() {
      return this.humanReadableType === HumanReadableAssetTypes.LABORER
    },
  },
  methods: {
    ...mapActions([
      'fetchReport',
      'fetchReportAssets',
      'updateReportSuccess',
      'updateReport',
      'batchAssignAssets',
      'addDefaultShiftItem',
    ]),
    async maybeCloneAssets() {
      this.isShowingControls = true
      if (!this.confirmClone) {
        this.confirmClone = true
        setTimeout(() => {
          if (!this.working) {
            this.confirmClone = false
          }
        }, 3000)
      } else {
        this.isCloning = true
        this.confirmClone = false
        try {
          await this.cloneAssets()
        } catch (error) {
          throw new Error(error)
        }
      }
    }, // maybeCloneAssets

    async cloneAssets() {
      if (!this.humanReadableType) {
        this.$notify({
          title: 'You must select an asset type to clone',
          type: 'warn',
        })
        return false
      }
      try {
        fullstory.event('assets_clone.start', {
          humanReadableType: this.humanReadableType,
        })
        // ---> maybe prevent cloning if there are any assets on destination report?
        this.$notify(`Ensuring destination has no ${this.humanReadableType}...`)
        if (!this.knackAssetType) {
          this.$notify({
            title: `Invalid Asset Type : "${this.humanReadableType}"!`,
            type: 'warn',
            duration: 7000,
          })
          this.isCloning = false
          fullstory.event('assets_clone.invalid_type', {
            humanReadableType: this.humanReadableType,
          })
          return false
        }

        await this.fetchDestinationAssignments()

        await this.fetchMyCloneableAssets()

        if (!this.cloneableAssets.length) {
          this.$notify({
            title: `All Assets Found!`,
            text: `All the assets you're trying to clone are already on the Report`,
            type: 'warn',
            duration: 7000,
          })
          this.isCloning = false
          return false
        }

        let { successes, errors } = await this.assignClones()

        if (successes.length) {
          let updatedReport = await this.addReportRevisionForClones(successes)

          await this.addDefaultShiftItems(successes)

          await this.updateReportSuccess({ updatedReport })
        }
        if (errors.length) {
          this.$notify({
            type: 'warn',
            title: `There were ${errors.length} Failed Clones.`,
            duration: 7000,
          })
          fullstory.event(`assets_clone.fail`, {
            destinationReportId: this.destinationReportId,
            numErrors: errors.length,
            numSuccesses: successes.length,
          })
        } else {
          fullstory.event(`assets_clone.ok`, {
            destinationReportId: this.destinationReportId,
            numSuccesses: successes.length,
          })
        }

        // fetch the destination report and update it in state
        this.fetchReport(this.destinationReportId)
        let updatedReport = await this.fetchReport(this.destinationReportId)
        this.updateReportSuccess({ updatedReport })

        this.isShowingControls = false
        this.cloneStartTimes = false
        this.cloneStartLocations = false
      } catch (error) {
        throw new Error(error)
      } finally {
        fullstory.event('assets_clone.finally', {
          humanReadableType: this.humanReadableType,
        })
        this.isCloning = false
      }
    }, // cloneAssets

    async fetchDestinationAssignments() {
      this.destinationAssgnts = []
      try {
        this.destinationAssgnts = await this.fetchReportAssets({
          assetType: this.knackAssetType,
          reportId: this.destinationReportId,
        })

        // Instead of canceling the whole operation,
        // omit destinationAssgnts from the batch we're going to clone
        // and then continue the operation

        if (this.destinationAssgnts.length) {
          this.$notify({
            title: `${this.humanReadableType} Found!`,
            text: 'Finding remaining cloneable assets...',
            duration: 7000,
          })
        }
      } catch (error) {
        return Promise.reject(new Error(error))
      }
    }, // fetchDestinationAssignments

    async fetchMyCloneableAssets() {
      this.cloneableAssets = []
      try {
        let myAssignments = await this.fetchReportAssets({
          assetType: this.knackAssetType,
          reportId: this.report.ID,
        })
        // iterate over each asset.ASSET_ID_FIELD and create an assignment

        let destinationAssetIds = this.destinationAssgnts.map((a) =>
          _get(a, this.assetIdKey)
        )

        this.cloneableAssets = myAssignments.filter(
          (a) => !destinationAssetIds.includes(_get(a, this.assetIdKey))
        )
      } catch (error) {
        return Promise.reject(new Error(error))
      }
    }, // fetchMyCloneableAssets

    async assignClones() {
      try {
        let {
          assignmentType,
          assetIdKey,
          reportConnectionKey,
          assetConnectionKey,
          knackAssetType,
        } = this
        this.$notify(`Cloning ${this.humanReadableType} Assignments...`)

        let cloneableFieldLookup = {
          [AssetTypes.MATERIALS]: ['QUANTITY_SENT_OUT'],
        }
        let cloneableLaborerFields = []
        if (this.cloneStartTimes) {
          cloneableLaborerFields.push('START_TIME')
        }
        if (this.cloneStartLocations) {
          cloneableLaborerFields.push('LOCATION_START_')
        }
        cloneableFieldLookup[AssetTypes.LABORER] = cloneableLaborerFields
        let cloneableFields = _get(cloneableFieldLookup, knackAssetType, [])

        let assetAssgntResponses = await this.batchAssignAssets({
          assets: this.cloneableAssets,
          assignmentType,
          reportId: this.destinationReportId,
          reportConnectionKey,
          assetConnectionKey,
          assetIdKey,
          cloneableFields,
        })
        let errors = assetAssgntResponses.filter((r) => r instanceof Error)
        let successes = assetAssgntResponses.filter(
          (r) => !(r instanceof Error)
        )
        if (successes.length) {
          this.$notify({
            type: 'success',
            title: `Created ${successes.length} Assignments!`,
          })
          this.$notify(`Finalizing New Assignments...`)
        }
        return { successes, errors }
      } catch (error) {
        return Promise.reject(new Error(error))
      }
    }, // assignClones

    async addDefaultShiftItems(successfulAssignments) {
      try {
        let defaultShiftItemPromises = await successfulAssignments.map(
          (response) => {
            let assetType = this.cloneableTypes[this.humanReadableType]

            let payload = {
              assetType,
              shiftId: response.ID,
              reportId: response.DAILY_REPORT[0]?.id,
            }

            return () => this.addDefaultShiftItem(payload)
          }
        )

        let defaultShiftResponses = await pAll(defaultShiftItemPromises, {
          concurrency: 3,
        })
        let errors = defaultShiftResponses.filter((r) => r instanceof Error)
        let successes = defaultShiftResponses.filter(
          (r) => !(r instanceof Error)
        )
        if (successes.length === defaultShiftItemPromises.length) {
          this.$notify({
            type: 'success',
            title: `Finalized all assignments!`,
          })
          fullstory.event(`assets_clone__default_shifts.ok`)
        } else if (successes.length) {
          this.$notify({
            type: 'success',
            title: `Finalized ${successes.length} assignments!`,
          })
          fullstory.event(`assets_clone__default_shifts.ok`, {
            destinationReportId: this.destinationReportId,
            numSuccesses: successes.length,
          })
          fullstory.event(`assets_clone__default_shifts.fail`, {
            destinationReportId: this.destinationReportId,
            numErrors: errors.length,
          })
        } else {
          this.$notify({
            type: 'warn',
            title: `There were ${errors.length} Failed Clones. Please check the new assignments for errors.`,
            duration: 7000,
          })
          fullstory.event(`assets_clone__default_shifts.fail ALL`, {
            destinationReportId: this.destinationReportId,
            numErrors: errors.length,
          })
        }
      } catch (error) {
        return Promise.reject(new Error(error))
      }
    }, // addDefaultShiftItems

    async addReportRevisionForClones(successes) {
      try {
        let assetNames = successes.map(
          (a) => a[this.assetTypeKey][0].identifier
        )

        let mods = {
          ID: this.destinationReportId,
          changeGroups: {
            [this.humanReadableType]: {
              added: assetNames,
            },
          },
        }
        let updatedReport = await this.updateReport(mods)
        return updatedReport
      } catch (error) {
        return Promise.reject(new Error(error))
      }
    }, // addReportRevisionForClones

    showControlsForType(type) {
      this.humanReadableType = type
      this.cloneStartTimes = false
      this.cloneStartLocations = false
      this.isShowingControls = true
    }, // showControlsForType
  }, // methods
}
</script>
