<template>
  <div>
    <p>
      <ReportModalAssetChip
        v-for="type of AssignableAssetTypes"
        :key="`${type}-chip`"
        :icon="AssetTypeIcons[type]"
        :asset-type="type"
        :report-id="report.ID"
        :loaded-assets="loadedAssets"
        :is-deleting="isDeleting"
        @assetsLoaded="$emit('assetsLoaded', type, $event)"
      />
    </p>
  </div>
</template>

<script>
import {
  AssetTypes,
  AssetTypeIcons,
  AssignableAssetTypes,
} from '@constants/knack'
import upperFirst from 'lodash/upperFirst'

import ReportModalAssetChip from '@modals/CalCellReport/ReportModalAssetChip'
export default {
  name: 'ReportModalAssetChips',
  components: { ReportModalAssetChip },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    isDeleting: {
      type: Object,
      required: true,
    },
    loadedAssets: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      AssetTypes,
      upperFirst,
      AssetTypeIcons,
      AssignableAssetTypes,
    }
  },

  methods: {
    properAssetName(assetType) {
      return assetType
        .split('_')
        .map((token) => upperFirst(token))
        .join(' ')
    }, // properAssetName
  },
}
</script>

<style lang="scss">
.assignment-chip {
  ::v-deep .spinner--wrap {
    display: flex;
    align-items: center;
  }
}
</style>
