<script>
import {
  mapActions,
  mapGetters,
  // mapState
} from 'vuex'
import _sortBy from 'lodash/sortBy'

export default {
  name: 'WithReportAssets',
  props: {
    assetType: {
      type: String,
      default: null,
    },
    reportId: {
      type: String,
      default: null,
    },
    sortBy: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      areAssignmentsLoading: false,
      error: null,
    }
  },
  computed: {
    ...mapGetters(['getReportAssets']),
    reportAssets() {
      if (!this.areAssignmentsLoading) {
        let reportAssets = this.getReportAssets({
          assetType: this.assetType || this.$route.params.assetType,
          reportId: this.reportId || this.$route.params.reportId,
        })
        this.$emit('loaded', reportAssets)
        return reportAssets
      }
      return []
    },
    sortedAssets() {
      if (this.sortBy.length) {
        return _sortBy(this.reportAssets, this.sortBy)
      }
      return this.reportAssets
    }, // sortedAssets
  },
  watch: {
    assetType: {
      async handler(val) {
        if (!val) return
        this.areAssignmentsLoading = true
        this.error = false
        try {
          await this.fetchReportAssets({
            assetType: this.assetType || this.$route.params.assetType,
            reportId: this.reportId || this.$route.params.reportId,
          })
        } catch (error) {
          this.error = true
        } finally {
          this.areAssignmentsLoading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchReportAssets']),
  },
  render() {
    return this.$scopedSlots.default({
      reportAssets: this.sortedAssets,
      areAssignmentsLoading: this.areAssignmentsLoading,
      reportAssetsError: this.error,
    })
  },
}
</script>
