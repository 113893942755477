var render = function render(){var _vm=this,_c=_vm._self._c;return (typeof _vm.report === 'object')?_c('VFlex',{class:[
    'shift',
    { 'night-shift': _vm.isNightShift },
    { 'new-shift': _vm.isNew },
    { protected: _vm.isProtected },
    { 'has-notes': _vm.hasNotes },
    { 'is-processing': _vm.isProcessing },
    { 'cannot-drag': _vm.cannotDrag },
    { ghost: _vm.report.isGhost },
    _vm.isImmutable ? 'immutable' : 'mutable',
    _vm.elevation,
    { 'green accent-2': _vm.showReportModal },
    { 'loc-hover green accent-2': _vm.isMyLocationHovering },
    { 'on-hold': _vm.isLocationOnHold },
    { 'needs-setup': _vm.needsSetup && !_vm.isProcessing },
  ],attrs:{"text-md-left":""},on:{"mouseover":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false},"click":function($event){$event.stopPropagation();return _vm.reportClick.apply(null, arguments)}}},[(_vm.isProcessing)?_c('BaseSpinner',{attrs:{"size":"small","line-fg-color":"#BBB","margin":"5"}}):_c('div',[_c('div',{staticClass:"cal-cell-data job-number"},[_vm._v(_vm._s(_vm.report.JOB_NUMBER)+" - "+_vm._s(_vm.location.CLIENT_NAME)+" "),(_vm.isLocked)?_c('VIcon',{staticClass:"right",attrs:{"small":"","color":"grey"}},[_vm._v("lock")]):_vm._e(),(_vm.isLocationOnHold)?_c('VIcon',{staticClass:"on-hold-icon right",attrs:{"small":"","color":"orange"}},[_vm._v("pause_circle_filled")]):(_vm.isCompleted)?_c('VIcon',{staticClass:"right",attrs:{"small":"","color":"grey"}},[_vm._v("check")]):_vm._e()],1),_c('div',{staticClass:"cal-cell-data small-data",attrs:{"title":_vm.location.IDENTIFIER}},[_vm._v(_vm._s(_vm.location.IDENTIFIER))]),_c('div',{staticClass:"cal-cell-data small-data"},[_vm._v(_vm._s(_vm.fiberJobAttrs.join(' | ')))]),(_vm.isNightShift)?_c('VIcon',{staticClass:"night-shift-icon",attrs:{"small":"","color":"#136085"}},[_vm._v("brightness_3")]):_vm._e(),(_vm.hasNotes)?_c('VIcon',{attrs:{"small":"","color":"#c837d5"}},[_vm._v("note")]):_vm._e(),(_vm.isVzInspectionDate)?_c('VIcon',{staticClass:"d-inline text-xs-right mx-1",attrs:{"small":"","color":"#2c70ea"}},[_vm._v("person_search")]):_vm._e(),(_vm.isInstall5gFoundationDate)?_c('VIcon',{staticClass:"d-inline text-xs-right mx-1",attrs:{"small":"","color":"#de7113"}},[_vm._v("5g")]):_vm._e(),(_vm.showReportModal)?_c('ReportModal',{attrs:{"report":_vm.report,"location":_vm.location,"show":_vm.showReportModal},on:{"hide":function($event){_vm.showReportModal = false}}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }