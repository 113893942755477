<template>
  <VDialog
    v-model="showPopup"
    :max-width="500"
    :disabled="working"
    :persistent="working"
  >
    <VCard :class="['report-popup text-xs-left', { 'grey--text': working }]">
      <ModalCloseButton :disabled="working" @click="$emit('hide')" />
      <VCardTitle
        :class="[
          isDeleteConfirmed
            ? 'red darken-1 white--text'
            : isDeleteAssgntsConfirmed
            ? 'orange darken-2 white--text'
            : 'green accent-2',
        ]"
      >
        <VFlex xs12 mb-2>Daily Report Info</VFlex>
        <VFlex
          xs12
          class="title font-weight-bold report-title"
          @click="openReport"
          >{{ report.DAILY_REPORT_NAME }}</VFlex
        >
      </VCardTitle>
      <transition name="fade-fast" mode="out-in">
        <VCardText class="font-weight-medium">
          <p>{{ report.LOCATION_ADDRESS }}</p>
          <p
            >Permit Dates : {{ location.PERMITTED_START_DATE.date }} –
            {{ location.PERMITTED_END_DATE.date }}</p
          >
          <p>{{ report.PERMIT_INFO }}</p>
          <p
            >Last Setup : <span v-if="lastSetupDate">{{ lastSetupDate }}</span
            ><span v-else><i>No Setup Found</i></span></p
          >
          <p
            >PM's Location Notes :
            <span v-if="location.PROJECT_MANAGER_NOTES.length">{{
              location.PROJECT_MANAGER_NOTES
            }}</span
            ><span v-else><i>No Notes</i></span></p
          >
          <VLayout wrap py-2
            ><VFlex xs6 mb-2>Phase : {{ report.LOCATION_PHASE_ }}</VFlex
            ><VFlex xs6 mb-2>LF : {{ location.LF ? location.LF : '-' }}</VFlex
            ><VFlex xs6 mb-2>Status : {{ report.REPORT_STATUS }}</VFlex
            ><VFlex xs6 mb-2>
              Protected :
              {{ location.PROTECTED === 'Yes' ? 'Yes' : 'No' }}</VFlex
            ><VFlex xs6 mb-2>Job Type : {{ jobType }}</VFlex>
            <VFlex xs6 mb-2>VZ? : {{ location.VZ || 'TBD' }}</VFlex>
          </VLayout>
          <p v-if="location.OWNER_REPRESENTATIVE"
            >Owner Rep : {{ location.OWNER_REPRESENTATIVE }}</p
          >
          <transition name="fade-fast" mode="out-in">
            <p v-if="hasAssignments && !isLockedOrCompleted && canEditScheduler"
              ><i>
                This Report has Equipment & Labor data that must be cleared
                first before moving or deleting
              </i>
              <BaseButton
                :disabled="working"
                color="orange darken-2"
                :outline="!isDeleteAssgntsConfirmed"
                :flat="!isDeleteAssgntsConfirmed"
                small
                @click="maybeDeleteAssgnts"
                >{{
                  !isDeleteAssgntsConfirmed
                    ? 'Clear All Attachments'
                    : 'Are you sure?'
                }}</BaseButton
              >
            </p>
            <p v-else-if="isLockedOrCompleted"
              ><i>
                This Report is {{ report.REPORT_STATUS }} and cannot be moved or
                deleted.
              </i>
            </p>
          </transition>

          <ReportModalAssetChips
            :report="report"
            :is-deleting="isDeleting"
            :loaded-assets="loadedAssets"
            @assetsLoaded="assetsLoaded"
          />
          <ReportModalCloningModule
            :report="report"
            :loaded-assets="loadedAssets"
          />
        </VCardText>
      </transition>
      <ReportModalFooter
        v-bind="footerProps"
        @maybeDelete="maybeDelete"
        @saveReport="saveReport"
        @hide="hide"
      />
    </VCard>
  </VDialog>
</template>

<script>
import { UPDATE_SCHED_REPORT } from '@constants/mutations'
import { ReportStatuses, AssetTypes } from '@constants/knack'
import { mapActions, mapGetters } from 'vuex'
import _get from 'lodash/get'

import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'
import ReportModalAssetChips from '@modals/CalCellReport/ReportModalAssetChips'
import ReportModalCloningModule from '@modals/CalCellReport/ReportModalCloningModule'
import ReportModalFooter from '@modals/CalCellReport/ReportModalFooter'
import ModalCloseButton from '@modals/ModalCloseButton'

export default {
  name: 'ReportModal',
  components: {
    VDialog,
    VCardText,
    ReportModalAssetChips,
    ReportModalCloningModule,
    ReportModalFooter,
    ModalCloseButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    report: {
      type: [Object, String],
      default: '',
    },
    location: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      PROJECT_MANAGER_NOTES: this.location.PROJECT_MANAGER_NOTES,
      isDeleteConfirmed: false,
      isDeleteAssgntsConfirmed: false,
      working: false,
      isDeleting: {
        [AssetTypes.LABORER]: false,
        [AssetTypes.EQUIPMENT]: false,
        [AssetTypes.MATERIALS]: false,
        [AssetTypes.PAYWORK_ITEM]: false,
      },
      loadedAssets: {
        [AssetTypes.LABORER]: false,
        [AssetTypes.EQUIPMENT]: false,
        [AssetTypes.MATERIALS]: false,
        [AssetTypes.PAYWORK_ITEM]: false,
      },
      requestTime: 0,
      spinnerMessage: 'Updating...',
    }
  },
  computed: {
    ...mapGetters(['canEditScheduler']),
    dirty() {
      return this.PROJECT_MANAGER_NOTES !== this.location.PROJECT_MANAGER_NOTES
    }, // dirty
    mods() {
      return {
        PROJECT_MANAGER_NOTES: this.PROJECT_MANAGER_NOTES,
      }
    }, // mods
    canBeDeleted() {
      return (
        this.areAssignmentsLoaded &&
        !this.hasAssignments &&
        !this.isLockedOrCompleted
      )
    }, // canBeDeleted
    isLockedOrCompleted() {
      return [ReportStatuses.LOCKED, ReportStatuses.COMPLETED].includes(
        this.report.REPORT_STATUS
      )
    }, // isLockedOrCompleted
    jobType() {
      return typeof this.location.JOB_TYPE === 'object'
        ? this.location.JOB_TYPE.join(', ')
        : this.location.JOB_TYPE
        ? this.location.JOB_TYPE
        : '-'
    }, // jobType
    areAssignmentsLoaded() {
      return (
        Object.keys(this.loadedAssets).filter(
          (assetType) => this.loadedAssets[assetType] === false
        ).length === 0
      )
    }, // areAssignmentsLoaded
    hasAssignments() {
      let numAssgnts =
        Number(_get(this.loadedAssets, AssetTypes.LABORER, 0)) +
        Number(_get(this.loadedAssets, AssetTypes.EQUIPMENT, 0)) +
        Number(_get(this.loadedAssets, AssetTypes.MATERIALS, 0)) +
        Number(_get(this.loadedAssets, AssetTypes.PAYWORK_ITEM, 0))
      return numAssgnts > 0
    }, // hasAssignments
    showPopup: {
      get() {
        return this.show
      },
      set(val) {
        if (!val) {
          this.$emit('hide')
        }
      },
    },
    footerProps() {
      return {
        report: this.report,
        location: this.location,
        working: this.working || !this.canEditScheduler,
        canBeDeleted: this.canBeDeleted,
        isDeleteConfirmed: this.isDeleteConfirmed,
        dirty: this.dirty,
      }
    }, // footerProps
    lastSetupDate() {
      return _get(
        this.location,
        'MOST_RECENT_SETUP_DATE_FORMULA.date_formatted'
      )
    }, // lastSetupDate
  }, // computed
  methods: {
    ...mapActions([
      'fetchReportAssets',
      'deleteReportAssets',
      'updateSchedReport',
      'deleteSchedReport',
      'fetchReport',
      'updateReport',
    ]),
    maybeDelete() {
      if (!this.isDeleteConfirmed) {
        this.isDeleteConfirmed = true
        setTimeout(() => {
          if (!this.working) {
            this.isDeleteConfirmed = false
          }
        }, 3000)
      } else {
        this.deleteReport()
      }
    }, // maybeDelete
    maybeDeleteAssgnts() {
      if (!this.isDeleteAssgntsConfirmed) {
        this.isDeleteAssgntsConfirmed = true
        setTimeout(() => {
          if (!this.working) {
            this.isDeleteAssgntsConfirmed = false
          }
        }, 3000)
      } else {
        this.isDeleteAssgntsConfirmed = false
        this.clearAssignments()
      }
    }, // maybeDeleteAssgnts

    hide() {
      this.$emit('hide')
    }, // hide

    assetsLoaded(assetType, assets) {
      this.loadedAssets[assetType] = assets.length
    }, // assetsLoaded

    async saveReport() {
      this.working = true
      this.spinnerMessage = 'Saving the Report...'

      let oldReport = this.report
      let report = { ID: this.report.ID, ...this.mods }
      let updatedReport = await this.updateSchedReport({ report, oldReport })
      this.working = false
      this.$emit('hide')
      return updatedReport
    }, // saveReport

    async deleteReport() {
      this.working = true
      this.spinnerMessage = 'Deleting Report...'

      let wasDeleted = await this.deleteSchedReport(this.report)
      // TODO (ES) : determine logic here. what is returned from the delete
      // call and what can we test to see if it was successful?
      if (wasDeleted) {
        this.$emit('hide')
      }
      this.working = false
    }, // deleteReport

    async clearAssignments() {
      this.working = true
      try {
        //
        // Laborer Assgnts
        //
        let refreshedLaborAssets = await this.fetchClearAndUpdateAssignments({
          assetType: AssetTypes.LABORER,
          assetTitle: 'Laborers',
        })
        this.loadedAssets[AssetTypes.LABORER] =
          refreshedLaborAssets.refreshedAssets.length
        // eslint-disable-next-line
        console.log({ refreshedLaborAssets })

        //
        // Equipment Assgnts
        //
        let refreshedEquipmentAssets = await this.fetchClearAndUpdateAssignments(
          {
            assetType: AssetTypes.EQUIPMENT,
            assetTitle: 'Equipment',
          }
        )
        this.loadedAssets[AssetTypes.EQUIPMENT] =
          refreshedEquipmentAssets.refreshedAssets.length
        // eslint-disable-next-line
        console.log({ refreshedEquipmentAssets })

        //
        // Material Usages
        //
        let refreshedMaterials = await this.fetchClearAndUpdateAssignments({
          assetType: AssetTypes.MATERIALS,
          assetTitle: 'Materials',
        })
        this.loadedAssets[AssetTypes.MATERIALS] =
          refreshedMaterials.refreshedAssets.length
        // eslint-disable-next-line
        console.log({ refreshedMaterials })

        //
        // Completed Work Qntys
        //
        let refreshedPayItems = await this.fetchClearAndUpdateAssignments({
          assetType: AssetTypes.PAYWORK_ITEM,
          assetTitle: 'Pay Items',
        })
        this.loadedAssets[AssetTypes.PAYWORK_ITEM] =
          refreshedPayItems.refreshedAssets.length
        // eslint-disable-next-line
        console.log({ refreshedPayItems })

        // use the returned delete responses to create a new set of report revisions
        // (imitate v1 revision structure)
        let deletedLaborerNames = refreshedLaborAssets.delResponse.map(
          (r) => r.asset.LABORER_NAME
        )
        let deletedEquipNames = refreshedEquipmentAssets.delResponse.map(
          (r) => r.asset.EQUIPMENT_NAME
        )
        let deletedMaterialNames = refreshedMaterials.delResponse.map(
          (r) => r.asset.MATERIAL_NAME
        )
        let deletedPayItemNames = refreshedPayItems.delResponse.map(
          (r) => r.asset.PAYWORK_ITEM_NAME
        )

        let mods = {
          changeGroups: {},
        }

        if (deletedLaborerNames.length) {
          mods.changeGroups.Manpower = {
            removed: deletedLaborerNames,
          }
        }
        if (deletedEquipNames.length) {
          mods.changeGroups.Equipment = {
            removed: deletedEquipNames,
          }
        }
        if (deletedMaterialNames.length) {
          mods.changeGroups.Materials = {
            removed: deletedMaterialNames,
          }
        }
        if (deletedPayItemNames.length) {
          mods.changeGroups['PayWork Items'] = {
            removed: deletedPayItemNames,
          }
        }
        if (Object.keys(mods.changeGroups).length) {
          let updatedReport = await this.updateReport({
            ...mods,
            ID: this.report.ID,
          })
          this.$store.commit(UPDATE_SCHED_REPORT, updatedReport)
        }

        this.working = false
      } catch (e) {
        console.error(e)
        this.working = false

        this.spinnerMessage = ''
      }
    }, // clearAssignments

    async fetchClearAndUpdateAssignments(options) {
      try {
        let { assetType, assetTitle } = options
        /**
         * FETCH ASSIGNMENTS
         */
        this.isDeleting[assetType] = true
        let assignments = await this.fetchReportAssets({
          assetType: assetType,
          reportId: this.report.ID,
        })

        // Short circuit w/zero assets
        if (!assignments.length) {
          this.isDeleting[assetType] = false
          await this.$notify({
            type: 'success',
            title: `No ${assetTitle} assets to remove`,
          })
          return { refreshedAssets: [], delResponse: [] }
        }

        // eslint-disable-next-line
        console.log({ assignments })
        await this.$notify(`Removing ${assignments.length} ${assetTitle}...`)
        this.spinnerMessage = `Removing ${assignments.length} ${assetTitle}...`

        /**
         * DELETE assignments
         */

        let delResponse
        try {
          delResponse = await this.deleteReportAssets({
            assetType: assetType,
            assets: assignments,
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('deleteReportAssets error', { error })
        }
        await this.$notify(
          `Done removing ${delResponse.length} ${assetTitle}...`
        )
        // eslint-disable-next-line
        console.log({ delResponse })

        /**
         * then check for updated assgnt's on the record
         */
        let refreshedAssets = await this.fetchReportAssets({
          assetType: assetType,
          reportId: this.report.ID,
        })

        // eslint-disable-next-line
        console.log({ refreshedAssets })

        this.isDeleting[assetType] = false
        return { refreshedAssets, delResponse }
      } catch (e) {
        console.error(e)
        this.working = false

        this.spinnerMessage = ''
      }
    }, // fetchClearAndUpdateAssignments
    openReport() {
      let reportRoute = `/locations/${this.location.ID}/dailys/${this.report.ID}`
      this.$router.push({
        path: reportRoute,
      })
    }, // openReport
  },
}
</script>

<style>
.report-title {
  cursor: pointer;
}
</style>
