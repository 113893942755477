<template>
  <VCardActions class="report-modal-footer">
    <BaseButton
      :disabled="working || !canBeDeleted"
      color="red darken-2"
      :outline="!isDeleteConfirmed"
      :flat="!isDeleteConfirmed"
      small
      @click="$emit('maybeDelete')"
      >{{ !isDeleteConfirmed ? 'Delete Report' : 'Are you sure?' }}</BaseButton
    >
    <BaseButton
      :disabled="working"
      color="blue darken-1"
      outline
      small
      :to="`/locations/${location.ID}/dailys/${report.ID}`"
      >View</BaseButton
    >
    <VSpacer></VSpacer>
    <BaseButton
      :disabled="working"
      color="blue darken-1"
      outline
      @click="$emit('hide')"
      >Close</BaseButton
    >
    <BaseButton
      :disabled="working || !dirty"
      depressed
      color="blue darken-1"
      @click="$emit('saveReport')"
      >Save</BaseButton
    >
  </VCardActions>
</template>

<script>
import { VCardActions } from '@vuetify/VCard'

export default {
  name: 'ReportModalFooter',
  components: { VCardActions },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    location: {
      type: Object,
      default: () => {},
    },
    working: {
      type: Boolean,
      default: false,
    },
    canBeDeleted: {
      type: Boolean,
      default: false,
    },
    isDeleteConfirmed: {
      type: Boolean,
      default: false,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>
